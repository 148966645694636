<template>
	<main-table-a>
		<div slot="table" class="f1 df fdc">
			<div class="df tac fww">
				<!-- 状态 -->
				<div class="mb20 mr20 br5 df oh jcsb bcf8f6f0" v-if="employee_status">
					<div :class="['p10 miw80 cp', {'bce2d6ba': params.status == s}]" @click="getEmployees({ ...params, page: 1, status: params.status == s ? '' : s })" v-for="(status, s) in employee_status" :key="s">
						<div class="fs14 c6">
							{{status}}
							<el-tooltip v-if="employee_tooltips[s]">
								<div slot="content" v-html="employee_tooltips[s]"></div>
								<i class="el-icon-question ch"></i>
							</el-tooltip>
						</div>
						<div class="fs16 fw6 mt5 cb7a06b">{{counts[s] || 0}}</div>
					</div>
				</div>
				<!-- 性别 -->
				<div class="mb20 mr20 br5 df oh jcsb bcf8f6f0" v-if="theme.width > 1440">
					<div :class="['p10 miw80 cp', {'bce2d6ba': params.gender === 1}]" @click="getEmployees({ ...params, page: 1, gender: params.gender === 1 ? '' : 1 })">
						<div class="fs14 c6">小哥哥</div>
						<div class="fs16 fw6 mt5 cb7a06b">{{counts['gender_1'] || 0}}</div>
					</div>
					<div :class="['p10 miw80 cp', {'bce2d6ba': params.gender === 2}]" @click="getEmployees({ ...params, page: 1, gender: params.gender === 2 ? '' : 2 })">
						<div class="fs14 c6">小姐姐</div>
						<div class="fs16 fw6 mt5 cb7a06b">{{counts['gender_2'] || 0}}</div>
					</div>
				</div>
				<!-- 性质 -->
				<div class="mb20 mr20 br5 df oh jcsb bcf8f6f0" v-if="theme.width > 1440">
					<div :class="['p10 miw80 cp', {'bce2d6ba': params.official === 1}]" @click="getEmployees({ ...params, page: 1, official: params.official === 1 ? '' : 1 })">
						<div class="fs14 c6">正式</div>
						<div class="fs16 fw6 mt5 cb7a06b">{{counts['official'] || 0}}</div>
					</div>
					<div :class="['p10 miw80 cp', {'bce2d6ba': params.official === 0}]" @click="getEmployees({ ...params, page: 1, official: params.official === 0 ? '' : 0 })">
						<div class="fs14 c6">试用</div>
						<div class="fs16 fw6 mt5 cb7a06b">{{counts['trial'] || 0}}</div>
					</div>
				</div>
				<!-- 类型 -->
				<div class="mb20 mr20 br5 df oh jcsb bcf8f6f0">
					<div :class="['p10 miw80 cp', {'bce2d6ba': type.checked}]" v-for="(type, t) in type_tabs" :key="t" @click="toggleType(t)">
						<div class="fs14 c6">
							{{type.name}}
							<el-tooltip v-if="employee_tooltips['type_'+t]">
								<div slot="content" v-html="employee_tooltips['type_'+t]"></div>
								<i class="el-icon-question ch"></i>
							</el-tooltip>
						</div>
						<div class="fs16 fw6 mt5 cb7a06b">{{counts['type_'+t] || 0}}</div>
					</div>
				</div>
				<div class="mb10">
					<el-button type="primary" size="mini" @click="show_fillter = !show_fillter" :plain="!show_fillter">
						{{show_fillter ? '收起' : '筛选'}}
						<el-tooltip v-if="employee_tooltips['filter']">
							<div slot="content" v-html="employee_tooltips['filter']"></div>
							<i class="el-icon-question ch"></i>
						</el-tooltip>
					</el-button>
					<el-button type="primary" size="mini" @click="employee_dialog = true;">
						添加
						<el-tooltip v-if="employee_tooltips['create']">
							<div slot="content" v-html="employee_tooltips['create']"></div>
							<i class="el-icon-question ch"></i>
						</el-tooltip>
					</el-button>
				</div>
			</div>
			<el-form :inline="true" :model="params" :size="theme.size" v-if="show_fillter">
				<el-form-item >
					<el-input v-model="params.keyword" placeholder="请输入姓名/手机号码/电子邮箱/身份证/银行卡/现居地址" clearable></el-input>
				</el-form-item>
				<el-form-item v-if="!admin_user.shop_id">
					<el-select v-model="params.shop_id" placeholder="城市站点" filterable clearable>
						<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-cascader style="width: 100%;" placeholder="请选择所属部门" v-model="params.departments" :options="tree_departments" :props="{expandTrigger: 'hover', value: 'unique_id', label: 'name', checkStrictly: true}" filterable clearable></el-cascader>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.roles" placeholder="系统角色" filterable  multiple collapse-tags>
						<el-option class="df aic jcsb" v-for="role in roles" :key="role.id" :label="role.name" :value="role.id">
							<span>{{ role.name }}</span>
							<span class="c9 fs12">{{counts["role_"+role.id] || 0}}</span>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select slot="label" v-model="params.date_type" placeholder="请选择时间类型" filterable clearable>
						<el-option label="添加时间" value="created"></el-option>
						<el-option label="更新时间" value="updated"></el-option>
					</el-select>
					<el-date-picker v-model="params.date" :picker-options="$config.DATE_PICKER" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getEmployees({ ...params, page: 1})">查询</el-button>
				</el-form-item>
			</el-form>
			<div class="f1 pr">
				<el-table ref="employee_table" class="scroll-wrapper pa employees" height="100%" :header-cell-style="{backgroundColor: '#e1d6ba', color: '#666'}" :data="tables" :size="theme.size" :row-class-name="tableRowClassName" @sort-change="sortChange">
					<el-table-column label="分公司" prop="shop.name" v-if="!admin_user.shop_id"></el-table-column>
					<el-table-column label="员工名称" min-width="120">
						<div class="df aic" slot-scope="scope">
							<el-avatar size="small" class="mr5" :src="scope.row.avatar || 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png'"></el-avatar>
							<el-link type="primary" @click="editEmployee(scope.row)">{{scope.row.name}}</el-link>
						</div>
					</el-table-column>
					<el-table-column label="OA部门" min-width="90">
						<template slot-scope="scope">
							<el-tooltip v-if="oa_users[scope.row.id]" :content="oa_users[scope.row.id].name">
								<span>{{scope.row | format_department(oa_users, oa_departments)}}</span>
							</el-tooltip>
							<el-link type="primary" @click="createOA(scope.row)" v-else>关联</el-link>
						</template>
					</el-table-column>
					<el-table-column label="任职岗位" min-width="80">
						<template slot-scope="scope">
							<template v-if="scope.row.position">
								<el-tooltip effect="light" v-if="scope.row.position.status == 4">
									<div slot="content">
										<el-timeline v-if="scope.row.transfer_positions" reverse>
											<el-timeline-item placement="top" v-for="position in scope.row.transfer_positions" :type="position.official ? 'success' : 'info'" :key="position.id" :timestamp="position.created_at">
												<div class="mt10">
													<h4 class="mb5 c3">{{position.name}}</h4>
													<p class="c6 fs212">{{position.comment}}</p>
												</div>
											</el-timeline-item>
										</el-timeline>
									</div>
									<el-link type="primary" @click="$refs['position'].open({...scope.row.position, id: 0, who: 'hr'})">{{scope.row.position.name}}</el-link>
								</el-tooltip>
								<el-link type="danger" @click="$refs['position'].open({...scope.row.position, id: 0, who: 'hr'})" v-else>{{transfer_statuses[scope.row.position.status] || '未知状态'}}</el-link>
							</template>
							<el-link type="primary" @click="$refs['position'].open({employee_id: scope.row.id, who: 'hr'})" v-else>配置</el-link>
						</template>
					</el-table-column>
					<template v-if="theme.width >= 1080">
						<el-table-column label="真实姓名" min-width="80">
							<template slot-scope="scope">
								<template v-if="scope.row.enrollment">
									<el-tooltip v-if="scope.row.enrollment.status == 4">
										<div slot="content" class="lh1_5">
											<p>编号：{{ scope.row.enrollment.no }}</p>
											<p>民族：{{ enrollment_nations[scope.row.enrollment.nation] || '未知' }}</p>
											<p>婚姻：{{ enrollment_marriages[scope.row.enrollment.marriage] || '未知' }}</p>
											<p>籍贯：{{ scope.row.enrollment.place ? scope.row.enrollment.place.name : '未知' }}</p>
											<p>户籍：{{ scope.row.enrollment.census ? scope.row.enrollment.census.name : '未知' }}</p>
											<p>{{ enrollment_id_types[scope.row.enrollment.id_type] }}：{{ scope.row.enrollment.id_number }}</p>
											<p>电子邮箱：{{ scope.row.enrollment.email || '无' }}</p>
											<p>现居地址：{{ scope.row.enrollment.current_address }}</p>
											<p>紧急联系：{{ scope.row.enrollment.emergency_name }} {{ scope.row.enrollment.emergency_mobile }}</p>
											<p v-if="scope.row.enrollment.deposit_bank">开户银行：{{ scope.row.enrollment.deposit_bank }}</p>
											<p v-if="scope.row.enrollment.bank_number">银行卡号：{{ scope.row.enrollment.bank_number }}</p>
											<p>学校专业：{{ scope.row.enrollment.graduation || '无' }} - {{ scope.row.enrollment.profession || '无' }}</p>
											<p>政治面貌：{{ enrollment_politics[scope.row.enrollment.politics] }}</p>
											<p>最高学历：{{ enrollment_edus[scope.row.enrollment.education] }}</p>
											<p>入职日期：{{ scope.row.enrollment.hired_at }} - {{scope.row.enrollment.valid_at || '长期'}}</p>
											<p>出生日期：{{ scope.row.enrollment.birthed_at }}</p>
											<p>登记日期：{{ scope.row.enrollment.created_at }}</p>
											<p>{{scope.row | diff_hired}}</p>
										</div>
										<el-link type="primary" @click="$refs['enrollment'].open({...scope.row.enrollment, who: 'hr'})">{{scope.row.enrollment.name}}</el-link>
									</el-tooltip>
									<el-link type="danger" @click="$refs['enrollment'].open({...scope.row.enrollment, who: 'hr'})" v-else>{{enrollment_status[scope.row.enrollment.status] || '未知状态'}}</el-link>
								</template>
								<el-link type="primary" @click="$refs['enrollment'].open({employee_id: scope.row.id, name: scope.row.name, who: 'hr'})" v-else>登记</el-link>
							</template>
						</el-table-column>
						<el-table-column label="性别" :formatter="({ enrollment }) => { return enrollment && genders[enrollment.gender] || '/'}" min-width="50"></el-table-column>
						<el-table-column label="类型" :formatter="({ enrollment }) => { return enrollment && enrollment_types[enrollment.type] || '/'}" min-width="50"></el-table-column>
						<el-table-column label="手机号码" prop="enrollment.mobile" min-width="100"></el-table-column>
						<el-table-column label="入职日期" prop="enrollment.hired_at" min-width="90" sortable="custom"></el-table-column>
					</template>
					<el-table-column label="入职登记" min-width="70" v-else>
						<template slot-scope="scope">
							<template v-if="scope.row.enrollment">
								<el-tooltip v-if="scope.row.enrollment.status == 4">
									<div slot="content" class="lh1_5">
										<p>编号：{{ scope.row.enrollment.no }}</p>
										<p>性别：{{ genders[scope.row.enrollment.gender] || '未知' }}</p>
										<p>民族：{{ enrollment_nations[scope.row.enrollment.nation] || '未知' }}</p>
										<p>婚姻：{{ enrollment_marriages[scope.row.enrollment.marriage] || '未知' }}</p>
										<p>籍贯：{{ scope.row.enrollment.place ? scope.row.enrollment.place.name : '未知' }}</p>
										<p>户籍：{{ scope.row.enrollment.census ? scope.row.enrollment.census.name : '未知' }}</p>
										<p>{{ enrollment_id_types[scope.row.enrollment.id_type] }}：{{ scope.row.enrollment.id_number }}</p>
										<p>手机号码：{{ scope.row.enrollment.mobile || '无' }}</p>
										<p>电子邮箱：{{ scope.row.enrollment.email || '无' }}</p>
										<p>现居地址：{{ scope.row.enrollment.current_address }}</p>
										<p>紧急联系：{{ scope.row.enrollment.emergency_name }} {{ scope.row.enrollment.emergency_mobile }}</p>
										<p v-if="scope.row.enrollment.deposit_bank">开户银行：{{ scope.row.enrollment.deposit_bank }}</p>
										<p v-if="scope.row.enrollment.bank_number">银行卡号：{{ scope.row.enrollment.bank_number }}</p>
										<p>学校专业：{{ scope.row.enrollment.graduation || '无' }} - {{ scope.row.enrollment.profession || '无' }}</p>
										<p>政治面貌：{{ enrollment_politics[scope.row.enrollment.politics] }}</p>
										<p>最高学历：{{ enrollment_edus[scope.row.enrollment.education] }}</p>
										<p>入职类型：{{ enrollment_types[scope.row.enrollment.type] }}</p>
										<p>入职日期：{{ scope.row.enrollment.hired_at }} - {{scope.row.enrollment.valid_at || '长期'}}</p>
										<p>出生日期：{{ scope.row.enrollment.birthed_at }}</p>
										<p>登记日期：{{ scope.row.enrollment.created_at }}</p>
										<p>{{scope.row | diff_hired}}</p>
									</div>
									<el-link type="primary" @click="$refs['enrollment'].open({...scope.row.enrollment, who: 'hr'})">{{scope.row.enrollment.name}}</el-link>
								</el-tooltip>
								<el-link type="danger" @click="$refs['enrollment'].open({...scope.row.enrollment, who: 'hr'})" v-else>{{enrollment_status[scope.row.enrollment.status] || '未知状态'}}</el-link>
							</template>
							<el-link type="primary" @click="$refs['enrollment'].open({employee_id: scope.row.id, name: scope.row.name, who: 'hr'})" v-else>登记</el-link>
						</template>
					</el-table-column>
					<el-table-column label="基金保险" min-width="70">
						<el-link slot-scope="scope" type="primary" @click="$refs['insurances'].open(scope.row)">{{scope.row | insurances}}</el-link>
					</el-table-column>
					<el-table-column label="基本薪酬" min-width="80">
						<el-link slot-scope="scope" type="primary" @click="$refs['config'].open(scope.row)">{{scope.row | format_configs}}</el-link>
					</el-table-column>
					<!-- 在业务列表查看，这个列表查看意义不大 -->
					<!-- <el-table-column label="业务权重" prop="clue_weight" min-width="90" sortable="custom"></el-table-column> -->
					<el-table-column label="系统角色" min-width="140">
						<template slot-scope="scope">
							<el-tag size="mini" class="mr5" v-for="role in scope.row.roles" :key="role.id">{{role.name}}<template v-if="!admin_user.shop_id">@{{role.shop ? role.shop.name : theme.group }}</template></el-tag>
						</template>
					</el-table-column>
					<el-table-column label="登录账户" min-width="90">
						<el-tooltip slot-scope="scope">
							<div slot="content" class="lh1_5">
								<template v-if="scope.row.user">
									<p v-if="scope.row.user.mobile"><i class="el-icon-mobile"></i> {{scope.row.user.mobile}}</p>
									<p v-if="scope.row.user.email"><i class="el-icon-message"></i> {{scope.row.user.email}}</p>
									<!-- <p>请尽量由用户修改手机邮箱密码，非必要请勿在此重置</p> -->
								</template>
								<template v-else>暂无登录账户</template>
							</div>
							<!-- <el-link type="primary" @click="$refs['show_user'].open(scope.row.user)">{{scope.row.user.name}}</el-link> -->
							<span>{{scope.row.user.name}}</span>
						</el-tooltip>
					</el-table-column>
					<template v-if="theme.width > 1440">
						<el-table-column label="更新时间" prop="updated_at" min-width="140" sortable="custom"></el-table-column>
						<el-table-column label="添加时间" prop="created_at" min-width="140" sortable="custom"></el-table-column>
					</template>
				</el-table>
			</div>
		</div>
		<el-pagination
			slot="footer"
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getEmployees({ ...params, page: 1, perPage: v }) }"
			@current-change="(v) => { getEmployees({ ...params, page: v }) }"
			background>	
		</el-pagination>
		<template slot="other">
			<el-dialog width="380px" :title="edit_employee.id ? '编辑员工' : '添加员工'" :visible.sync="employee_dialog" @closed="edit_employee = {}" append-to-body destroy-on-close>
				<el-form label-width="80px" ref="employee_form" :model="edit_employee" :rules="employee_rules" :size="theme.size" status-icon>
					<el-form-item label-width="0px">
						<!-- :before-upload="beforeAvatarUpload" -->
						<el-upload
							class="up-avatar tac"
							:action="$api.URI_UPLOADS"
							:data="{type: employee_avatar_cfg.type}"
							:on-success="handleAvatarSuccess"
							:show-file-list="false">
							<img class="avatar" :src="edit_employee.avatar" v-if="edit_employee.avatar">
							<i class="el-icon-plus avatar c9 fs28 tac" v-else></i>
							<div class="el-upload__tip" slot="tip">只能上传 {{employee_avatar_cfg.ext.join('/').toUpperCase()}}  文件，且不超过 {{employee_avatar_cfg.format_size_unit}}</div>
						</el-upload>
					</el-form-item>
					<el-form-item label="员工名称" prop="name">
						<el-input v-model="edit_employee.name" placeholder="请输入名称" clearable></el-input>
					</el-form-item>
					<el-form-item label="系统角色" prop="roles" v-if="edit_employee.id">
						<!-- :disabled="!$utils.checkAuth('employees', 'roles')" -->
						<el-select style="width: 100%;" placeholder="请输入关键词搜索" v-model="edit_employee.roles" filterable multiple>
							<el-option class="df aic jcsb" v-for="role in roles" :key="role.id" :value="role.id" :label="admin_user.shop_id ? role.name : `${role.name}@${role.shop ? role.shop.name : theme.group}`">
								<span>{{ role.name }}</span>
								<span class="c9 fs12 c8492a6" v-if="!admin_user.shop_id">{{ role.shop ? role.shop.name : theme.group }}</span>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="手机号码" prop="mobile" v-else>
						<el-input v-model="edit_employee.mobile" placeholder="请输入已注册系统账户的手机号码" clearable></el-input>
						<p class="c9 fs12">即 <c-tips class="fs12"></c-tips> 小程序完成注册</p>
					</el-form-item>
					<el-form-item label="线索权重" prop="clue_weight">
						<el-input-number v-model="edit_employee.clue_weight" :min="0" :max="99" placeholder="选填"></el-input-number>
					</el-form-item>
					<el-form-item label="对外介绍" prop="introduce">
						<el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}" placeholder="请输入对外介绍。一般服务人员对所人C端可见，建议同步OA" v-model="edit_employee.introduce"></el-input>
					</el-form-item>
					<el-form-item label="备注说明" prop="remarks">
						<el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}" placeholder="请输入备注说明。只内部列表可见，一般指人事查看" v-model="edit_employee.remarks"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('employee_form')">{{edit_employee.id ? '提交保存' : '提交添加'}}</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
			<departure-show ref="departure" @refresh="getEmployees(params)"></departure-show>
			<employee-insurances ref="insurances" @refresh="getEmployees(params)"></employee-insurances>
			<transfer-position ref="position" @refresh="getEmployees(params)"></transfer-position>
			<enrollment-show ref="enrollment" @refresh="getEmployees(params)" @departure="(e) => { return $refs['departure'].open(e); }"></enrollment-show>
			<show-user ref="show_user" @refresh="getEmployees(params)"></show-user>
			<configs ref="config" @refresh="getEmployees(params)"></configs>
		</template>
	</main-table-a>
</template>

<style>
	.el-table.employees .warning-row {
		background: oldlace;
	}

	.el-table.employees .danger-row {
		background: #F56C6C;
	}

	.el-table.employees .success-row {
		background: #f0f9eb;
	}

	.el-table.employees .info-row {
		background: #f1f1f1;
	}
	.up-avatar .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	.up-avatar .el-upload:hover {
		border-color: #409EFF;
	}
	.up-avatar .avatar {
		display: block;
		width: 128px;
		height: 128px;
		line-height: 128px;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import moment from 'moment';
	import departureShow from '@/pages/hrm/departures/show';
	import enrollmentShow from '@/pages/hrm/enrollments/show';
	import transferPosition from '@/pages/hrm/positions/transfer';
	import employeeInsurances from '@/pages/hrm/employees/insurances';
	import mainTableA from '@/components/main-table-a';
	import showUser from '@/pages/urm/show.vue';
	import cTips from '@/components/c-tips';
	import configs from './configs';

	export default {
		components: {
			mainTableA,
			departureShow,
			enrollmentShow,
			transferPosition,
			employeeInsurances,
			showUser,
			cTips,
			configs
		},
		computed: {
			...mapState(['theme', 'models', 'admin_user', 'employee', 'employees', 'oa_users', 'oa_departments']),
			employee_avatar_cfg () {
				return this.$utils.uploadConfig('employee_avatar');
			},
			tree_departments () {
				return this.$utils.buildTree(Object.values(this.oa_departments));
			},
			enrollment_dict () {
				if (!this.models) return {};
				return this.models.enrollments || {};
			},
			employee_dict () {
				if (!this.models) return {};
				return this.models.employees || {};
			},
			transfer_dict () {
				if (!this.models) return {};
				return this.models.transfer_positions || {};
			},
			employee_status () {
				return this.employee_dict.status || {};
			},
			employee_tooltips () {
				return this.employee_dict.tooltips || {};
			},
			enrollment_edus () {
				return this.enrollment_dict.edus || {};
			},
			enrollment_types () {
				return this.enrollment_dict.types || {};
			},
			enrollment_status () {
				return this.enrollment_dict.status || {};
			},
			enrollment_nations () {
				return this.enrollment_dict.nations || {};
			},
			enrollment_politics () {
				return this.enrollment_dict.politics || {};
			},
			enrollment_marriages () {
				return this.enrollment_dict.marriages || {};
			},
			enrollment_id_types () {
				return this.enrollment_dict.id_types || {};
			},
			transfer_statuses () {
				return this.transfer_dict.statuses || {};
			}
		},
		filters: {
			diff_hired ({ separated_at, enrollment }) {
				if (!enrollment) return "未办理入职登记！";
				if (!enrollment.hired_at) return "未办理入职登记！";
				var last = moment();
				var quitted = false;
				if (separated_at) {
					const separated = moment(separated_at);
					if (separated.add(1, 'days').isBefore(last)) {
						last = separated;
						quitted = true;
					}
				}
				const diff_days = Math.abs(last.diff(enrollment.hired_at, 'days'));
				const years = Math.floor(diff_days / 365);
				const months = Math.floor((diff_days % 365) / 30.4375);
				const days = Math.floor((diff_days % 365) % 30.4375);
				var text = `累计 ${diff_days} 天`;
				if (months) {
					text = `${months} 月 ${days} 天。${text}`;
				}
				if (years) {
					text = `${years} 年 ${text}`;
				}
				// 注意：以上代码中的% 30.4375是近似一个月30天的计算，对于大部分情况是准确的，但是在闰年二月会有误差，如果需要精确的月份差异，可以通过遍历每一个月来计算。
				if (quitted) {
					return `最后到岗：${separated_at} 任职 ${text}`;
				}
				return `已加入 ${text}`;
			},
			format_department ({ id }, users, departments) {
				if (!users[id]) return '未关联OA';
				const { main_department } = users[id];
				if (!departments[main_department]) return '未知部门';
				return departments[main_department].name;
			},
			format_configs ({salary_configs}) {
				let amount = 0;
				for (let welfare of salary_configs) {
					if (welfare.salary_field && welfare.salary_field.type == 1) {
						amount += welfare.amount;
					}
				}
				return +(amount/100).toFixed(2) + ' 元';
			},
			insurances ({insurance_funds}) {
				let a = 0;
				let b = 0;
				const chineseNumbers = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
				for (let insurance of insurance_funds) {
					const { insurance_temp } = insurance;
					if (insurance_temp) {
						if (insurance_temp.type == 1) {
							a += 1;
						}
						if (insurance_temp.type == 2) {
							b += 1;
						}
					}
				}
				if (!a && !b) return '投保';
				if (!a) return `${chineseNumbers[b]}金`;
				if (!b) return `${chineseNumbers[a]}险`;
				return `${chineseNumbers[a]}险${chineseNumbers[b]}金`;
			}
		},
		methods: {
			sortChange (e) {
				let o = e.order ? (e.prop+':'+e.order) : null;
				let p = this.params;
					p.order = o;
				this.getEmployees(this.params);
			},
			toggleType (s) {
				const { type } = this.params
				const index = type.indexOf(s);
				if (index > -1) {
					type.splice(index, 1);
					this.type_tabs[s].checked = false;
				} else {
					type.push(s);
					this.type_tabs[s].checked = true;
				}
				this.getEmployees({ ...this.params, page: 1 });
			},
			editEmployee(e) {
				this.edit_employee = { ...e, roles: e.roles.map((r) => { return r.id; }) };
				this.employee_dialog = true;
			},
			tableRowClassName({ row }) {
				if (row.separated_at) {
					if (moment(row.separated_at).add(1, 'days').isBefore(moment())) return 'info-row';
				}
				if (row.enrollment) {
					if (row.enrollment.status == 4) return 'success-row';
					return 'warning-row';
				}
				return '';
			},
			handleAvatarSuccess ({ code, msg, result }) {
				if (code) return this.$message.error(msg);
				const { id, url, status } = result;
				if (status != "success") return this.$message.error(status);
				this.edit_employee = { ...this.edit_employee, avatarid: id, avatar: url };
			},
			async getEmployees (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_EMPLOYEES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.tables = result.data;
				this.shops = result.shops;
				this.roles = result.roles;
				this.counts = result.counts;
				var type_tabs = {};
				const type = params.type || [];
				for (var t in this.enrollment_types) {
					type_tabs[t] = {
						name: this.enrollment_types[t],
						checked: type.indexOf(t) >= 0 ? true : false
					};
				}
				this.type_tabs = type_tabs;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			async handleEmployees (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_EMPLOYEES, {params, headers: { loading }});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				return this.$message.success(msg);
			},
			async submitEmployee (data) {
				const res = await this.$http.post(this.$api.URI_EMPLOYEES, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message.success({
					message,
					onClose: () => {
						this.getEmployees(this.params);
						this.employee_dialog = false;
					}
				});
			},
			createOA ({id, user}) {
				if (!user.mobile) return this.$message.error('员工暂未绑定手机号码');
				this.$confirm('请确保<b style="color: #c7000a;">' + user.mobile + '</b>手机号已存在OA通讯录<br />若出错的次数超出企业规模人数的20%，会导致1天不可调用。', '关联OA系统', {
					dangerouslyUseHTMLString: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.handleEmployees({action: 'oaid', id});
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'employee_form':
							this.submitEmployee(this.edit_employee);
						break;
					}
				});
			}
		},
		data() {
			const checkMobile = (rule, value, callback) => {
				const reg = /^1[3|4|5|6|7|8][0-9]\d{8}$/;
				if (value && !reg.test(value)) return callback(new Error('请输入正确的手机号'));
				return callback();
			}
			return {
				counts: {},
				edit_employee: {},
				type_tabs: {},
				roles: [],
				tables: [],
				genders: {1: '男', 2: '女'},
				shops: [],
				show_fillter: false,
				employee_dialog: false,
				params: {
					type: [],
					perPage: 10,
				},
				employee_rules: {
					name: [{ required: true, message: '必须填写员工名称', trigger: 'blur'}],
					mobile: [
						{ required: true, message: '必须填写手机号码', trigger: 'blur'},
						{ validator: checkMobile, trigger: ['blur', 'change'] }
					],
					shop_position_id: [{ required: true, message: '必须填写任职岗位', trigger: 'blur'}]
				}
			}
		},
		created () {
			this.getEmployees(this.params, true);
		}
	};
</script>